export const CALCULATIONS_TAG_EVENTS = {
  SUCCESS_CALCULATED_PAYROLL: 'CalculatedPayrollSuccess',
  FAILED_CALCULATED_PAYROLL: 'CalculatedPayrollFailed',
  PAYROLL_SUMMARIES_DOWLOADED: 'CalculatedSummaryDowloaded',
  PERIOD_SUMMARIES_DOWLOADED: 'CalculatedPeriodReportsDowloaded',
};

export const CALCULATIONS_EVENT_LABELS = {
  DOWNLOAD_PAYROLL_SUMMARY: 'PayrollSummaryDowloaded',
  DOWNLOAD_PERIOD_SUMMARY: 'PeriodsSummaryDowloaded',
  DOWNLOAD_EMPLOYEES_SUMMARY: 'WorkersSummaryDowloaded',
  DOWNLOAD_HORIZONTAL_REPORT: 'HorizontalReportDowloaded',
  DOWNLOAD_LIST_REPORT: 'LineListDowloaded',
  DOWNLOAD_EMPLOYEES_DETAIL_REPORT: 'DetailOfAllWorkersDowloaded',
  DOWNLOAD_EMPLOYEES_PERIOD_SUMMARY: 'PeriodWorkersSummaryDowloaded',
  DOWNLOAD_PRERECEIPT: 'PrereciboReportDowloaded',
};
